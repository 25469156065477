<template>
  <MasterDetail
    :actionBarButtons="actionBarButtons"
    :hasNewButton="false"
    :canEdit="false"
    :contextOptions="contextMenu"
    :disableActContextOnClick="true"
    ref="detail"
    :cols="cols"
    :customResource="customResource"
  >
  </MasterDetail>
</template>

<script lang="ts">
import MasterDetail from "@/components/master-detail.vue";
import { mapGetters } from "vuex";

export default {
  name: "CredenciaisComponent",
  components: {
    MasterDetail,
  },
  computed: {
    ...mapGetters(["clientId", "user"]),
    contextMenu() {
      const options = [
        {
          name: "Editar",
          limit: 1,
          show: this.userHasAccess('Cadastro.RPA.update'),
          cb: (row) => {
            this.$router.push({
              name: "edicao-credenciais",
              params: { id: row.id, item: row },
            });
          },
        }
      ];
      return options.filter(({ show }) => show);
    },
    actionBarButtons() {
      return [
        {
          text: "Adicionar",
          icon: "mdi-plus-box-outline",
          action: () => {
            this.$router.push({ name: "cadastro-credenciais", mode: "create" });
          },
        },
      ];
    },

    cols() {
      return [
        {
          key: "id",
          name: "Código",
          type: this.$fieldTypes.NUMBER,
          hideInTable: true,
        },
        {
          key: "plataforma",
          name: "Plataforma",
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "ano",
          name: "Ano",
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "representante",
          name: "Representante",
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "urlHtml",
          name: "URL",
          type: this.$fieldTypes.HTML,
          align: -1,
        },
        {
          key: "usuario",
          name: "Usuário",
          type: this.$fieldTypes.TEXT,
          copyToClipboard: true,
          nowrap: true,
          align: -1
        },
        {
          key: "senha",
          name: "Senha",
          type: this.$fieldTypes.PASSWORD,
          copyToClipboard: true,
          width: "200px",
        },
        {
          key: "beneficio",
          name: "Benefício",
          type: this.$fieldTypes.TEXT,
          hideInTable: true,
        },
        {
          key: "observacao",
          name: "Observação",
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "cpf",
          name: "CPF",
          type: this.$fieldTypes.TEXT,
          hideInTable: true,
        },
        {
          key: "ativo",
          name: "Ativo",
          type: this.$fieldTypes.TEXT,
          hideInTable: true,
        },
        {
          key: "fantasia",
          name: "Fantasia",
          type: this.$fieldTypes.TEXT,
          hideInTable: true,
        },
        {
          key: "cnpj",
          name: "CNPJ",
          type: this.$fieldTypes.TEXT,
          hideInTable: true,
        },
      ];
    },
    customResource() {
      const resource = this.apiResource(`/v1/rpa/${this.clientId}`);
      return {
        ...resource,
        get(args) {
          return resource.get({ ...args }).then((res) => {
            const ret = res.map((item) => ({
              ...item,
              urlHtml:
                item.url != null
                  ? `<a href="${item.url}" target="_blank" style="color: '#4747ff'; text-decoration: underline; z-index: 99; margin-left: -10px">${item.url}</a>`
                  : item.url,
            }));
            return ret;
          });
        },
      };
    },
  },
  data() {
    return {
      isCadastroVisible: false,
      cadastroModalData: {},
      opts: {},
    };
  },
};
</script>
